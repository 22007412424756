.loading-home {
    background: #29292d;
    _background: linear-gradient(90deg,#013971 0,#055eb2 53%,#013a73);;
    opacity: 0.95;
    color: white;
    position: absolute;
    height: 100%;
    width: 100%;
    text-align: center;
  }
  .match-detail-loading{
    width: 2rem !important;
    height: 2rem !important;
    position: relative;
    left: 50%;
}

.match-detail-loading-main {
    position: absolute;
    top:35%;
    left:30%;    
    right: 30%;
}

.main-spinner{
    width: 2rem !important;
    height: 2rem !important;
}
.loading-message{
    font-size: 24px;
    margin-top: 20px;
    font-weight: 700;
    color: #ffdf1b;

}

.loading-home-tryagain, .loading-home-tryagain:focus {
    background: #F44336;
    border: #F44336;
    padding: 7px !important;
    color: white;
    border-radius: 2px;
    font-weight: bolder;
    text-transform: uppercase;

}


/* just some  styling*/

  
  /* heart div class - in html i used just a simple html simbol entity for heart sign, you can use text or whatever u want*/
 
  
  /* we are using prefix free for this example */
  

  
  .heart {
  
      -webkit-animation: beat .75s infinite alternate;
      -moz-animation: beat .75s infinite alternate;
      -ms-animation: beat .75s infinite alternate;
      -o-animation: beat .75s infinite alternate;
      animation: beat .75s infinite alternate;
  
      -webkit-transform-origin: center;
      -moz-transform-origin: center;
      -o-transform-origin: center;
      -ms-transform-origin: center;
      transform-origin: center;
  }
  
  
  @keyframes beat {
   to {
      -webkit-transform: scale(1.2);
         -moz-transform: scale(1.2);
         -o-transform: scale(1.2);
         -ms-transform: scale(1.2);
         transform: scale(1.2);
   }
  
  }
  
  @-moz-keyframes beat {
   to {
     -moz-transform: scale(1.2);
     transform: scale(1.2);
   }
  
  }
  
  @-webkit-keyframes beat {
   to {
     -webkit-transform: scale(1.2);
     transform: scale(1.2);
   }
  
  }
  
  @-ms-keyframes beat {
   to {
     -ms-transform: scale(1.2);
     transform: scale(1.2);
   }
  
  }
  
  @-o-keyframes beat {
   to {
     -o-transform: scale(1.2);
     transform: scale(1.2);
   }
  
  }
  
  
  
  
  
  
  
  
  /**
 * ==============================================
 * Dot Falling
 * ==============================================
 */
.dot-falling {
  position: relative;
  left: -9794px;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: #9880ff;
  color: #9880ff;
  box-shadow: 9999px 0 0 0 #9880ff;
  animation: dotFalling 1s infinite linear;
  animation-delay: .1s;
}

.dot-falling::before, .dot-falling::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-falling::before {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dotFallingBefore 1s infinite linear;
  animation-delay: 0s;
}

.dot-falling::after {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dotFallingAfter 1s infinite linear;
  animation-delay: .2s;
}

@keyframes dotFalling {
  0% {
    box-shadow: 9999px -15px 0 0 rgba(152, 128, 255, 0);
  }
  25%,
  50%,
  75% {
    box-shadow: 9999px 0 0 0 #9880ff;
  }
  100% {
    box-shadow: 9999px 15px 0 0 rgba(152, 128, 255, 0);
  }
}

@keyframes dotFallingBefore {
  0% {
    box-shadow: 9984px -15px 0 0 rgba(152, 128, 255, 0);
  }
  25%,
  50%,
  75% {
    box-shadow: 9984px 0 0 0 #9880ff;
  }
  100% {
    box-shadow: 9984px 15px 0 0 rgba(152, 128, 255, 0);
  }
}

@keyframes dotFallingAfter {
  0% {
    box-shadow: 10014px -15px 0 0 rgba(152, 128, 255, 0);
  }
  25%,
  50%,
  75% {
    box-shadow: 10014px 0 0 0 #9880ff;
  }
  100% {
    box-shadow: 10014px 15px 0 0 rgba(152, 128, 255, 0);
  }
}